














































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedSettingChangePassword from "@/components/Shared/Setting/ChangePassword.vue";
import SharedSettingMenu from "@/components/Shared/Setting/Menu.vue";
import SharedSettingProfile from "@/components/Shared/Setting/Profile.vue";
import SharedSettingBankAccount from "@/components/Shared/Setting/BankAccount.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { maxFileSize, maxLength } from "@/helpers/InputRule";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
        SharedSettingProfile,
        SharedSettingBankAccount,
        SharedSettingMenu,
        SharedSettingChangePassword,
    },
})
export default class AppSetting extends Vue {
    public isLoading: boolean = false;
    public forceUpdate: number = 0;
    public selectedMenu: string = "profile";
    public menus: any[] = [
        { text: "Profil", icon: "mdi-account", value: "profile" },
        { text: "No. Rekening", icon: "mdi-card", value: "accountNumber" },
        { text: "Ganti Password", icon: "mdi-key", value: "changePassword" },
    ];
    public formData: any = {};

    get forms() {
        let forms: any[] = [
            {
                text: "Nama",
                value: "display_name",
                placeholder: "Nama anda atau nama organisasi",
                withLabel: true,
                required: true,
            },
            {
                text: "Jenis Identitas",
                value: "identity_type",
                type: "combobox",
                items: ["KTP"],
                placeholder: "Pilih salah satu",
                withLabel: true,
                required: true,
            },
            {
                text: "Nomor Identitas",
                value: "identity_number",
                placeholder: `Isi nomor ${
                    this.formData.identity_type
                        ? this.formData.identity_type
                        : "identitas"
                }`,
                withLabel: true,
                required: true,
                specialRule:
                    this.formData.identity_type === "KTP" ? true : false,
                rules: [...maxLength(16)],
            },
            {
                text: "Foto KTP",
                value: "identity",
                type: "image",
                imageValue: "identity_path",
                placeholder: "Pilih foto KTP",
                fileType: "image",
                withLabel: true,
                rules: [...maxFileSize(2)],
            },
            {
                text: "Nomor telepon",
                value: "phone_number",
                placeholder: "Nomor yang bisa dihubungi / nomor WA",
                withLabel: true,
                required: true,
                type: "decimal",
                rules: [...maxLength(13)],
            },
            {
                text: "Alamat",
                value: "address",
                type: "textarea",
                placeholder: "Alamat anda / organisasi",
                withLabel: true,
                required: true,
            },
            {
                text: "Tentang anda",
                value: "about",
                type: "textarea",
                placeholder: "Tentang anda / organisasi secara singkat...",
                withLabel: true,
            },
            {
                text: "Foto profil",
                value: "photo",
                type: "image",
                imageValue: "photo_path",
                placeholder: "Pilih foto profil",
                fileType: "image",
                withLabel: true,
            },

            {
                text: "Instagram username",
                value: "instagram_account",
                placeholder: "username_instagram",
                withLabel: true,
                prefix: "@",
            },
            {
                text: "Twitter username",
                value: "twitter_account",
                placeholder: "username_twitter",
                withLabel: true,
                prefix: "@",
            },
            {
                text: "Website",
                value: "website",
                placeholder: "https://website-anda.com",
                withLabel: true,
            },
        ];

        if (this.me && this.me.role.name === "COMPANY") {
            forms = [
                {
                    text: "Nama",
                    value: "display_name",
                    placeholder: "Nama anda atau nama organisasi",
                    withLabel: true,
                    required: true,
                },
                {
                    text: "Jenis Identitas",
                    value: "identity_type",
                    type: "combobox",
                    items: ["KTP", "NPWP"],
                    placeholder: "Pilih salah satu",
                    withLabel: true,
                    required: true,
                },
                {
                    text: "Nomor Identitas",
                    value: "identity_number",
                    placeholder: `Isi nomor ${
                        this.formData.identity_type
                            ? this.formData.identity_type
                            : "identitas"
                    }`,
                    withLabel: true,
                    required: true,
                    specialRule:
                        this.formData.identity_type === "KTP" ? true : false,
                },
                {
                    text: "Nomor telepon",
                    value: "phone_number",
                    placeholder: "Nomor yang bisa dihubungi / nomor WA",
                    withLabel: true,
                    required: true,
                },
                {
                    text: "Alamat",
                    value: "address",
                    type: "textarea",
                    placeholder: "Alamat anda / organisasi",
                    withLabel: true,
                    required: true,
                },
                {
                    text: "Tentang anda",
                    value: "about",
                    type: "textarea",
                    placeholder: "Tentang anda / organisasi secara singkat...",
                    withLabel: true,
                },
                {
                    text: "Foto profil",
                    value: "photo",
                    type: "image",
                    imageValue: "photo_path",
                    placeholder: "Pilih foto profil",
                    fileType: "image",
                    withLabel: true,
                },

                {
                    text: "Instagram Username",
                    value: "instagram_account",
                    placeholder: "Nama anda atau nama organisasi",
                    withLabel: true,
                    prefix: "@",
                },
                {
                    text: "Twitter Username",
                    value: "twitter_account",
                    placeholder: "Nama anda atau nama organisasi",
                    withLabel: true,
                    prefix: "@",
                },
                {
                    text: "Website",
                    value: "website",
                    placeholder: "https://website-anda.com",
                    withLabel: true,
                },
            ];
        }

        return forms;
    }

    public changePasswordMessages: any = {
        oldPassword: [],
        newPassword: [],
        confirmNewPassword: [],
    };

    get changePasswordForms() {
        const forms: any[] = [
            {
                text: "Password Lama",
                value: "oldPassword",
                placeholder: "Masukkan password anda sekarang",
                withLabel: true,
                required: true,
                isPassword: true,
                errorMessages: this.changePasswordMessages.oldPassword,
            },
            {
                text: "Password Baru",
                value: "newPassword",
                placeholder: "Masukkan password baru",
                withLabel: true,
                required: true,
                isPassword: true,
                errorMessages: this.changePasswordMessages.newPassword,
            },
            {
                text: "Konfirmasi Password Baru",
                value: "confirmNewPassword",
                placeholder: "Masukkan kembali password baru",
                withLabel: true,
                required: true,
                isPassword: true,
                errorMessages: this.changePasswordMessages.confirmNewPassword,
            },
        ];

        return forms;
    }

    resetChangePasswordMessages() {
        this.changePasswordMessages = {
            oldPassword: [],
            newPassword: [],
            confirmNewPassword: [],
        };
    }

    get me() {
        return this.$store.state.users.me;
    }

    public showDialogCard: boolean = false;
    public bankAccountData: any = {};
    get bankAccountForms() {
        const forms = [
            {
                text: "Nama Bank",
                value: "bank_name",
                placeholder: "Isi nama bank",
                withLabel: true,
                required: true,
            },
            {
                text: "No. Rekening",
                value: "account_number",
                placeholder: "Isi no. rekening",
                withLabel: true,
                required: true,
            },
            {
                text: "Atas Nama",
                value: "owner_name",
                placeholder: "Isi nama pemilik rekening",
                withLabel: true,
                required: true,
            },
            {
                text: "Kode Bank",
                value: "bank_code",
                placeholder: "Isi kode bank (opsional)",
                withLabel: true,
                required: false,
            },
        ];

        return forms;
    }

    async submitProfile(profile: any) {
        this.isLoading = true;
        const isEdit = !!profile.id;
        let response: any = null;

        if (isEdit) {
            response = await this.$store.dispatch(
                "users/updateProfile",
                profile
            );
        } else {
            response = await this.$store.dispatch("users/addProfile", profile);
        }

        setTimeout(() => {
            this.isLoading = false;
        }, 500);
    }

    async updatePassword(data: any) {
        this.isLoading = true;
        this.resetChangePasswordMessages();

        const { oldPassword, newPassword, confirmNewPassword } = data;
        const response = await this.$store.dispatch(
            "users/changePassword",
            data
        );

        console.log(response);

        const hasErrors = response && response.errors.length;

        if (hasErrors)
            response.errors.map((error: any) => {
                this.changePasswordMessages[error.field] = [error.message];
            });

        setTimeout(() => {
            this.isLoading = false;
        }, 500);
    }

    async createCard() {
        this.isLoading = true;
        const card = this.bankAccountData;
        const response = await this.$store.dispatch("cards/addCard", card);
        const isError = response && response.errors && response.errors.length;

        if (isError) console.log(response);

        const userId = this.$store.state.users.me.id;
        this.$store.dispatch("cards/getUserCards", userId);

        setTimeout(() => {
            this.isLoading = false;
            this.showDialogCard = false;
        }, 500);
    }

    async updateCard() {
        this.isLoading = true;
        const card = this.bankAccountData;
        const response = await this.$store.dispatch("cards/updateCard", card);
        const isError = response && response.errors && response.errors.length;

        if (isError) console.log(response);

        const userId = this.$store.state.users.me.id;
        this.$store.dispatch("cards/getUserCards", userId);

        setTimeout(() => {
            this.isLoading = false;
            this.showDialogCard = false;
        }, 500);
    }

    @Watch("me", { immediate: true })
    updateFormData(me: any) {
        if (me && me.profile) (this.formData = me.profile), this.forceUpdate++;
    }
}
