








































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseForm,
    },
})
export default class SharedSettingProfile extends Vue {
    @Prop() forms!: any[];
    @Prop() data!: any;
    @Prop() loading!: boolean;

    public isFormValid: boolean = false;

    @Emit("profile:submit")
    submitProfile() {
        return this.data;
    }

    dummyProfile() {
        this.data = {
            display_name: "Kiki Irwan Maulana",
            identity_type: "KTP",
            identity_number: "6304012345678001",
            phone_number: "0895634775903",
            address: "Sei. Lulut Komplek Dalem Sakti",
        };
    }

    mounted() {
        const isEmpty = (obj: any) => {
            return Object.keys(obj).length === 0;
        };

        if (!isEmpty(this.data)) this.isFormValid = true;
    }
}
