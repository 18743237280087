



































import BaseCard from "@/components/Base/BaseCard.vue";
import InputCombobox from "@/components/Input/InputCombobox.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        InputCombobox,
        BaseCard,
    },
})
export default class SharedSettingMenu extends Vue {
    @Prop() value!: string;
    @Prop() menus!: any[];

    get selectedMenu() {
        return this.value;
    }
    set selectedMenu(selectedMenu: any) {
        this.$emit("input", selectedMenu);
    }

    get translatedValue() {
        const selectedValue = this.value;
        const findSelectedMenu = this.menus.filter((menu: any) => {
            return menu.value === selectedValue;
        });

        return findSelectedMenu[0].text;
    }

    set translatedValue(selectedMenu: any) {
        this.$emit("input", selectedMenu);
    }
}
